<template>
  <div>
    <editor ref="editp" api-key="zqe14cm19y1aa115kf62m4u54t6mzmt9zmjcy9z0h22rubtv" :init="initcfg" v-model="htmlvalue" />
  </div>
</template>

<script>
var tinymce_this;
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'TextEditor',
  components:{'editor': Editor},
  props:{
    newValue:{
      type:String,
      default: ''
    },
    height:{
      type:Number,
      default: 500
    }
  },
  data(){
    return {
      ApiUrl:this.ApiUrl,
      htmlvalue:this.newValue,
      initcfg:{
        height: this.height,
        menubar: false,
        language:'zh_CN',
        theme:'silver',
        plugins: [
          'advlist autolink lists link image charmap searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount hr emoticons image axupimgs'
        ],
        fontSize:12,
        toolbar:[
          'undo redo restoredraft | cut copy paste pastetext | bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | table media charmap emoticons insertdatetime code fullscreen','formatselect fontselect fontsizeselect lineheight forecolor backcolor | bullist numlist blockquote subscript superscript hr removeformat | axupimgs '
        ],
        images_upload_handler:function (blobInfo,succFun,failFun){
          var file = blobInfo.blob(),formData = new FormData();
          formData.append('file', file, file.name );
          formData.append('dir', 'tinymce_upload');
          tinymce_this.requestApi('/adm/upload_img.html',formData,{'content-Type':'multipart/form-data'}).then(function (res){
            if(res.status==1) {
              succFun(res.url)
            }else{
              failFun(res.message);
            }
          })

        }
      }
    }
  },
  mounted() {
    tinymce_this=this;
  },
  watch:{
    newValue(newValue){
      var _this=this;
      setTimeout(function (){_this.htmlvalue=newValue;},300)
    }
  },
  methods:{
    getContent:function (){
      return this.htmlvalue;
    }
  }
}
</script>
<style lang="less"></style>
