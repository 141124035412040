<template>
	<div class="edit-news-main">
		<div class="save-btn">
			<Button type="info" icon="md-checkmark" @click="saveData">保存设置</Button>
			<Button icon="md-close" class="close" @click="close_page($route)">取消</Button>
		</div>
		<div class="searchw">
			<Form :model="data" :label-width="80">
				<FormItem label="指南标题" style="width: 600px;">
					<Input v-model="data.title"></Input>
				</FormItem>
				<FormItem label="缩略图">
					<div class="news-thumb"><img v-viewer v-if="data.litpic" :src="data.litpic" style="cursor:zoom-in;" /></div>
					<upload class="thumb-box" btnTxt="点击上传缩略图" action="upload_img.html" @upSuccess="upSuccess"></upload>
				</FormItem>
        <FormItem label="发布时间" style="width: 600px;">
          <DatePicker type="datetime" placeholder="不填则默认当前时间" v-model="data.add_time"></DatePicker>
        </FormItem>
				<FormItem label="内容" style="width: 1000px;">
					<text-editor ref="textEditor" :height="580" :newValue="data.content"></text-editor>
				</FormItem>
			</Form>
		</div>
	</div>
</template>

<script>
	import TextEditor from '@/components/editor/texteditor'
  import Upload from "@/components/public/upload";
	export default {
		name: "EditNews",
		components:{TextEditor,Upload},
		data(){
			return{
				id:0,
				data:{title:'', litpic:'', content:''},
			}
		},
		activated() {
			this.id = this.$route.query.id;
		},
		watch:{
			id(){
				if (this.id) this.init();
			}
		},
		methods:{
			init(){
				var _this = this;
				this.requestApi('/adm/get_news_dtl',{id:this.id}).then(function (res) {
					if(res.status==1){
						_this.data = res.data;
					}
				})
			},
			saveData(){
				var _this = this;
				this.data.content = this.$refs.textEditor.getContent();
				this.data.id=this.id;
				this.requestApi('/adm/upnews',this.data).then(function (res) {
					if(res){
						_this.tipSuccess(res.msg);
						_this.$router.push({'query':{'id':res.id}});
						_this.id=res.id;
					}else{
						_this.alertError(res.msg);
					}
				})
			},
      /**
       * 缩略图上传成功
       */
      upSuccess(res){
				this.data.litpic = res.response.url;
			}
		}
	}
</script>

<style lang="less">
.edit-news-main{
	padding: 10px 0;background: #ffffff;
	.thumb-box{
		display: inline-block;float: left;
	}
	.save-btn{
		position: fixed;right: 30px;z-index: 9;background: #ffffff;
		.close{
			margin-left: 8px;
		}
	}
	.news-thumb{
		position: relative;
    display: inline-block;
    float: left;margin-right: 10px;width: 120px;height: 80px;line-height: 80px;text-align: center;border: 1px solid transparent;border-radius: 2px;background: #fff;box-shadow: 0 0 5px rgba(0,0,0,0.2);overflow: hidden;
		img{
      margin-top: -3px;
			max-width: 100%;vertical-align: middle;
		}
	}
}
</style>
