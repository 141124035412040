<template>
  <div>
   <Upload ref="upload" name="file" :type="dragType"
   :show-upload-list="showUploadList"
   :default-file-list="defaultList"
   :format="formatArr"
   :max-size="maxSize"
   :multiple="multiple"
   :action="BaseAction+action"
   :data="extraDataNew"
   :headers="header"
   :on-format-error="handleFormatError"
   :on-exceeded-size="handleMaxSize"
   :before-upload="handleBeforeUpload"
   :on-progress="handleProgress"
   :on-success="handleSuccess"
   :on-error="handleError"
   :on-preview="handlePreview"
   :on-remove="handleRemove">
     <slot name="btn_view">
       <div :style="dragStyle" v-if="dragType == 'drag'">
         <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
         <p>{{percent!=-1?('已上传'+percent+'%'):btnTxt}}</p>
       </div>
       <Button v-else :type="percent!=-1?'default':'primary'" :loading="percent!=-1" :size="btnSize">
         <Icon v-if="percent!=-1" type="ios-cloud-upload" size="16" style="color: #ffffff"></Icon>
         {{percent!=-1?('已上传'+percent+'%'):btnTxt}}
       </Button>
     </slot>
   </Upload>
  </div>
</template>
<script>
    export default {
      name:'uploadFun',
      props: {
        dragType:{//select（点击选择），drag（支持拖拽）
          type:String,
          default:'select'
        },
        dragStyle:{//上传按钮样式
          type:String,
          default:'padding: 20px 0;'
        },
        multiple:{//多选
          type:Boolean,
          default:false
        },
        maxLength:{//多选开启时有效  最多上传几张图片
          type:Number,
          default:1
        },
        defaultList:{//已上传数组
          type:Array,
          default:()=>[]
        },
        formatArr:{//支持的文件类型 识别文件后缀
          type:Array,
          default:()=>['jpg','jpeg','png','GIF','JPG','JPEG','PNG']
        },
        imgName:{//文件名称编号
          type:String,
          default:''
        },
        showUploadList:{//是否显示已上传文件列表
          type:Boolean,
          default:false
        },
        maxSize: {//文件上传大小 KB
          type:Number,
          default:3072
        },
        extraData:{//额外参数
          type:Object,
          default:()=>{}
        },
        headers:{//请求头
          type:Object,
          default:()=>{}
        },
        action:{//请求地址
          type:String,
          default:'uploadImg'
        },
        isPercent:{
          type:Boolean,
          default:true
        },
        btnSize:{
          type:String,
          default:'default'
        },
        btnTxt:{
          type:String,
          default:'点击上传'
        }
      },
      data () {
        return {
          BaseAction:this.ApiUrl+'/adm/',
          uploadList: [],
          extraDataNew:null,
          percent:-1,
          header:{}
        }
      },
      watch:{
        percent(e){
          this.$emit('upProgress',{percent:e,'name':this.imgName})
        }
      },
      mounted () {
        this.uploadList = this.$refs.upload.fileList;
        this.initData();
      },
      methods: {
        initData:function(){
          var defaultOptions = {name:this.imgName}
          this.extraDataNew = {
            ...defaultOptions,
            ...this.extraData
          };
          var authorize = {authorize:this.getToken()}
          this.header = {
            ...authorize,
            ...this.headers
          }
        },
        UpExtraData:function (data){
          this.extraData=data;
          this.initData();
        },
        handleBeforeUpload () {
          this.percent = 0;
          if(!this.multiple) return true;
          const check = this.uploadList.length < this.maxLength;
          if (!check) {
            this.$Notice.warning({
              title: '最多可上传'+this.maxLength+'张图片'
            });
            this.percent = -1;
          }
          return check;
        },
        handleFormatError (file) {
          this.$Notice.warning({
              title: file.name+'文件格式不正确',
              desc: '待上传的文件格式必须为'+this.formatArr.toString()
          });
          this.percent = -1;
        },
        handleMaxSize (file) {
          this.$Notice.warning({
              title: file.name+'文件超出大小限制',
              desc: '文件大小不能超过' + this.maxSize+'KB'
          });
          this.percent = -1;
        },
        handleProgress (event){
          if(this.isPercent) this.percent = parseInt(event.percent);
        },
        handleSuccess (response, file) {
          this.percent = -1;
          setTimeout(()=>{
            if(response.status == 1){
              this.$emit('upSuccess',{response:response,name:this.imgName,file_name:file.name})
            }else{
              this.$Notice.warning({
                title: '上传失败',
                desc: response.message
              });
            }
          },100)
        },
        handleError (error){
          this.percent = -1;
          this.$Notice.warning({
              title: '上传失败',
              desc: error
          });
        },
        handleRemove(){

        },
        handlePreview (file) {
          console.log(file)
          //点击已上传的文件链接时的钩子
        }
      }
    }
</script>

<style>
</style>
